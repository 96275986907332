@media screen and (max-width: 1251px) {

  body {
    line-height: 1.5;
  }

  body.nav-opened {
    overflow-y: hidden;
    position: fixed;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0 0 1.5rem;
  }

  h1, .h1 {
    font-size: 2rem;
  }

  h2, .h2 {
    font-size: 2rem;
  }

  h3, .h3 {
    font-size: 2rem;
  }

  h4, .h4 {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .main-section {
    padding: 6rem 0;
  }

  .section-center {
    padding: 0 1.5rem;
  }

  .section-title:before,
  .section-title:after {
    margin-bottom: 1.5rem;
  }

  .section-title--center,
  .section-title--faded {
    margin-bottom: 3rem;
  }

  .section-title--vertical {
    padding-top: 1.5rem;
  }

  .section-title--vertical:after {
    margin: 0 1.5rem;
  }

  .kicker {
    font-size: 1.125rem;
    max-width: 29rem;
  }

  .inspire-text {
    font-size: 1.125rem;
    max-width: 29rem;
    margin: 0 auto;
  }

  /* CEO QUOTE SECTION */

  .ceo-quote {
    width: 100%;
    padding-left: 9rem;
    height: 35.875rem;
  }

  .ceo-quote__background {
    width: calc(100% - 9rem);
  }

  .ceo-quote__background::after {
    display: none;
  }

  .ceo-quote__container {
    top: 0;
    left: 0;
    width: 100%;
  }

  .ceo-quote__container::after {
    bottom: -3.75rem;
    left: -4.5rem;
    right: unset;
    transform: scale(-0.5, 0.5);
  }

  .ceo-quote__image {
    width: auto;
    height: 17.5rem;
    bottom: 0;
    right: 0;
    left: unset;
  }

  .ceo-quote__content {
    padding: 7rem 3.75rem 0;
  }

  .ceo-quote__text {
    font-size: 1rem;
    max-width: 60%;
  }

  .ceo-quote__text::before {
    transform: scale(0.67);
    top: -4.75rem;
    left: -0.53125rem;
  }

  .ceo-quote__name {
    font-size: 0.875rem;
    margin-top: 1.75rem;
  }

  .ceo-quote__position {
    font-size: 0.875rem;
  }


  /* CLIENT TESTIMONIALS SECTION */

  .client-testimonials {
    flex-direction: column;
  }

  .client-testimonials__icon {
    width: 2.5rem;
    height: 2.1875rem;
    margin: 0;
  }

  .client-testimonials__container {
    margin-top: 1.25rem;
  }

  .client-testimonials__header {
    font-size: .75rem;
    margin-bottom: 1.25rem;
  }

  .client-testimonials__text {
    font-size: 1rem;
    color: var(--font-base);;
    margin-bottom: 2.25rem;
    max-width: 38.75rem;
  }

  .client-testimonials .author__image {
    width: 3.375rem;
    height: 3.375rem;
  }

  .client-testimonials .author__content {
    margin-left: .1625rem;
  }

  .client-testimonials .author__name {
    font-weight: 700;
    font-size: .875rem;
  }

  .client-testimonials .author__title {
    font-size: .75rem;
  }

  /* HOW CAN WE HELP */
  .how-can-we-help__title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .how-can-we-help__text {
    margin-bottom: 3rem;
    max-width: 90%;
  }

  /* RECTANGLES */

  .rectangles {
    gap: 1.5rem;
  }

  .rectangles__item {
    padding: 2rem;
  }

  .rectangles__item:hover {
    transform: none;
  }

  .rectangles__item-wrapper {
    row-gap: 2.5rem;
  }

  .rectangles__item-content {
    row-gap: 1.5rem;
  }

  .rectangles__item-title {
    font-size: 1.375rem;
  }

  /* HOW WE SECTION */

  .how-we-recruit__container::before {
    transform: scale(0.5);
    transform-origin: top left;
    top: calc(-6rem - 50px);
  }

  .how-we-recruit__container::after {
    bottom: calc(-6rem - 50px);
    transform: scale(0.5);
    transform-origin: bottom right;
  }

  .how-we-recruit.light .how-we-recruit__container::before {
    transform: scale(0.5);
    transform-origin: top right;
  }

  .how-we-recruit.light .how-we-recruit__container::after {
    transform: scale(0.5);
    transform-origin: bottom left;
    left: -25px;
  }

  .how-we-recruit__items {
    margin-top: 3rem;
    grid-column-gap: 3rem;
    grid-row-gap: 2rem;
  }

  .how-we-recruit__item {
    column-gap: 1rem;
  }

  .how-we-recruit__item--title {
    font-size: 1.125rem;
  }

  .how-we-recruit__item--icon {
    width: 4rem;
    height: auto;
  }

  /* G-CORE SECTION */
  .g-core__wrapper:before {
    display: none;
  }

  .g-core__wrapper {
    justify-content: center;
    column-gap: 1.25rem;
  }

  .g-core__column {
    width: fit-content;
  }

  .g-core__column.left {
    padding-left: 0;
  }

  .g-core__header {
    font-size: .9375rem;
    margin-bottom: 2.5rem;
  }

  .g-core__header-text--before {
    padding-right: 1.125rem;
  }

  .g-core__header-text--after {
    padding-left: .8125rem;
  }

  .g-core__image {
    width: 12.5rem;
    height: fit-content;
  }

  .g-core__text {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .accordion {
    width: 100%;
    max-width: 31.25rem;
  }

  .accordion__label {
    padding: 3.75rem 1.25rem;
  }

  .accordion__content {
    padding: 0 1.25rem;
  }

  .accordion__content p {
    font-size: 1rem;
  }

  .accordion input:checked ~ .accordion__content {
    padding: .125rem 1.25rem 3.75rem 1.25rem;
  }

  /* NAVIGATION MENU */
  nav {
    width: 100vw;
  }

  .menu {
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0;
  }

  .menu__right {
    flex-direction: row-reverse;
  }

  .menu__items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--dark);
    flex-direction: column;
    justify-content: flex-start;
    align-self: unset;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5;
    max-height: 0;
    transition: max-height .2s ease-out;
    overflow-y: scroll;
  }

  .menu__item {
    margin-right: 0;
    padding: 1.6825rem 0 1.5rem;
    border-top: 1px solid rgba(233, 243, 255, 10%);
  }

  .menu__item-link:after {
    display: none
  }

  .menu__item:first-child {
    margin-top: 2rem;
  }

  .menu__item--socials {
    display: flex;
    padding: 3.5rem 1.25rem 1.5rem;
    justify-content: flex-start;
    font-size: 1.5rem;
    color: var(--white);
    margin-bottom: 5rem;
  }

  .menu__item--mobile {
    display: flex;
  }

  a.menu__socials-item,
  a.menu__socials-item:link,
  a.menu__socials-item:hover {
    color: var(--white);
    margin-right: 1rem;
    width: 1.5rem;
    text-align: center;
  }

  .menu__item-link,
  .menu__item-link:hover {
    padding-top: 0.25rem;
    color: var(--white);
  }

  .menu__item-link:after {
    margin-top: 0.5rem;
  }

  .menu__item--cta {
    display: none;
  }

  .menu__icon {
    cursor: pointer;
    display: inline-block;
    position: relative;
    user-select: none;
    width: 1.125rem;
    height: 1rem;
    transition: all .2s ease-out;
    margin-left: 1.5rem;
  }

  .menu__navicon {
    background: var(--dark);
    display: block;
    height: .125rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background .2s ease-out;
    width: 100%;
  }

  .nav-dark .menu__navicon {
    background: var(--white);
  }

  .nav-scrolled .menu__navicon {
    background: var(--dark);
  }

  .nav-scrolled .menu__item-link,
  .nav-scrolled .menu__item-link:hover {
    color: var(--white);
  }

  .menu__icon:before,
  .menu__icon:after {
    background: var(--dark);
    content: '';
    display: block;
    height: .125rem;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }

  .nav-dark .menu__icon:before,
  .nav-dark .menu__icon:after {
    background: var(--white);
  }

  .nav-scrolled .menu__icon:before,
  .nav-scrolled .menu__icon:after {
    background: var(--dark);
  }

  .menu__icon:before {
    top: 0;
  }

  .menu__icon:after {
    bottom: 0;
  }

  nav.mobile-dark {
    background-color: var(--dark);
  }

  nav.mobile-dark .menu__icon:before,
  nav.mobile-dark .menu__icon:after {
    background: var(--white);
  }

  nav.mobile-dark .menu__logo {
    filter: grayscale(1) invert(1);
  }

  nav.mobile-dark .menu__button--mobile {
    background: var(--accent);
  }

  .menu__btn:checked ~ .menu__items {
    max-height: calc(100vh - 6rem);
    height: calc(100vh - 6rem);
  }

  .menu__btn:checked ~ .menu__icon {
    width: 1rem;
    transform: scale(0.75);
  }

  .menu__btn:checked ~ .menu__icon .menu__navicon {
    background: transparent;
  }

  .menu__btn:checked ~ .menu__icon:before,
  .menu__btn:checked ~ .menu__icon:after {
    width: calc(1rem * 1.3);
  }

  .menu__btn:checked ~ .menu__icon:before {
    transform: rotate(45deg);
    transform-origin: top left;
  }

  .menu__btn:checked ~ .menu__icon:after {
    transform: rotate(-45deg);
    transform-origin: bottom left;
  }

  .menu__button--mobile {
    display: inline-block;
    font-size: .875rem;
    padding: 0.6rem 3rem 0.6rem 0.6rem;
    transition: background 50ms
  }

  .menu__navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* CONTACT */

  .contact {
    height: auto;
    align-items: flex-end;
  }

  .contact:before {
    height: 28rem;
  }

    .contact__content {
    padding: 0 1.5rem 2rem 2rem;
    width: 50%;
  }

  /* PAGE HEADER */
  .header {
    margin-top: -6rem;
  }

  .header__container {
    min-height: unset;
  }

  .header__corner {
    transform: scale(0.5);
  }

  .header__corner.rb {
    transform-origin: bottom right;
  }

  .header__title {
    font-size: 1.75rem;
  }

  .header__text {
    font-size: 1.125rem;
    line-height: 1.33;
    margin-bottom: 2rem;
  }


  /* ================================================ */

  /* CASE STUDIES */
  .studies__item-content {
    padding: 0 2rem 1.5rem;
  }

  .studies__item:hover::after {
    display: none;
  }

  .studies__item-logo {
    padding: 2rem 2rem 0;
  }

  /* ================================================ */
  /* REVIEWS */

  .reviews__kicker {
    font-size: 1.15rem;
    margin-bottom: 3.5rem;
    max-width: 90%;
  }

  .reviews {
    left: 0;
    right: 0;
    width: 100%;
  }

  .reviews .swiper-slide-active {
    padding-left: 2.5rem;
    padding-right: 1.25rem;
  }

  .reviews .swiper-slide-next {
    padding-left: 1.25rem;
    padding-right: 2.5rem;
  }

  .reviews__item {
    padding: 2rem 1.5rem 2rem;
  }

  .reviews__item-icon {
    left: 1.5rem;
    transform: scale(calc(2 / 3));
    transform-origin: top left;
  }

  .reviews__widget {
    margin-top: .625rem;
    padding-left: 2.5rem;
  }

  /* ================================================ */
  /* CUSTOMERS */
  .customers__corner {
    transform: scale(0.5);
  }

  .customers__corner.rt {
    transform-origin: top right;
  }

  .customers__text {
    font-size: 2rem;
    max-width: 80%;
    margin-bottom: 3rem;
  }

  .customers__brands {
    flex-wrap: wrap;
  }

  .customers__item {
    flex-shrink: 0;
    object-fit: contain;
    object-position: center;
  }

  /* WE MAKE IT BETTER */
  .it-better {
    padding: 1.5rem;
  }

  .it-better__background {
    left: 13.5rem;
  }

  .it-better__corner {
    transform: scale(0.5);
  }

  .it-better__corner.rt {
    transform-origin: top right;
    right: 1.5rem;
    top: 1.5rem;
  }

  .it-better__corner.lb {
    transform-origin: bottom left;
    bottom: 1.5rem;
    left: 27.5rem;
  }

  .it-better__white-block {
    width: 6rem;
    height: 6rem;
  }

  .it-better__content {
    left: 1.5rem;
    bottom: 1.5rem;
    padding: 2rem 1.5rem 2rem 0;
    width: 26rem;
  }

  .it-better__text {
    font-size: 1rem;
    line-height: 1.25;
  }

  .it-better__text--small {
    font-size: 0.875rem;
    line-height: 1.25;
    margin-bottom: 1rem;
  }

  /* TECH STACK */

  .tech-stack {
    gap: 5rem;
  }

  /* EXPERTS */
  #experts {
    padding-bottom: 3rem;
  }

  .experts__section-subtitle {
    font-size: 1.15rem;
    margin-bottom: 2.5rem;
  }

  .experts__content {
    padding: 1.5rem 1.5rem 0 0;
  }

  .experts__subtitle {
    font-size: 1.25rem;
  }

  .experts__text--small {
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }

  .experts__image {
    padding-bottom: 1.5rem;
  }

  .experts__image:before {
    top: 1.5rem;
    bottom: 0;
    left: 1.5rem;
    right: -1.5rem;
  }

  .experts__image:after {
    transform: scale(0.5);
    transform-origin: bottom left;
    bottom:1.5rem;
  }

  .experts__photo {
    min-width: unset;
    flex-shrink: 1;
  }

  .experts__corner {
    transform: scale(0.5);
  }

  .experts__corner.rb {
    transform-origin: bottom right;
    right: -1.5rem;
  }

  .experts__corner.lb {
    transform-origin: bottom left;
  }

  /* ---- SERVICES --- */
  .services {
    gap: 2rem;
  }
  .services__item-icon,
  .services__item-title {
    margin-bottom: 1rem;
  }

  .services__item-text {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  .services__item-subtext {
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }

  /* TECHNOLOGY */
  .technology {
    grid-template-rows: 3rem;
  }


  /* ================================================ */
  /* FOOTER */
  footer {
    padding: 4rem 0;
  }

  .footer__section {
    max-width: 10rem;
  }

  /* TESTIMONIALS */

  .testimonials {
    flex-direction: column;
  }

  /*=========================== WHATS IN ==================================*/

  .whats-in__kicker {
    max-width: 38rem;
    margin-top: 0;
    margin-bottom: 2.5rem;
  }

  .whats-in__content::before,
  .whats-in__content::after {
    display: none;
  }

  /* BOXES */
  .boxes {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.25rem;
  }

  .boxes .box {
    height: 20rem;
  }

  .boxes .box__image {
    height: 3rem;
  }

  .boxes .box__header {
    font-size: 1rem;
    margin-bottom: 1rem;
    height: 5rem;
  }

  .boxes .box__text {
    line-height: 1.15;
    font-size: 0.875rem;
  }


  /*=========================== KEY BENEFITS ==================================*/

  #key-benefits .boxes {
    grid-row-gap: 1.25rem;
  }

  #key-benefits .boxes .box {
    padding: 1.5rem;
  }

  #key-benefits .boxes .box__header {
    height: unset;
    margin-top: 2rem;
  }

  /*===================== PICK A PROJECT =========================*/


  .pick-project__container {
    column-gap: 1.5rem;
  }

  .pick-project__subtitle {
    line-height: 1.25;
    margin-bottom: 1.25rem;
  }

  .pick-project__list-item-title {
    font-size: 1.125rem;
  }

  .pick-project__list-item-text {
    line-height: 1.25;
    font-size: 0.95rem;
  }

  .pick-project__side {
    margin-bottom: 1.5rem;
  }

  .pick-project__side::before {
    right: -1.5rem;
    bottom: -1.5rem;
    width: 12rem;
    height: 12rem;
  }

  .pick-project__side--photo {
    min-width: unset;
  }

  .pick-project__corner {
    transform: scale(0.5);
  }

  .pick-project__corner.lt {
    transform-origin: top left;
  }

  .pick-project__corner.lb {
    transform-origin: bottom left;
    bottom: -1.5rem;
  }

  .pick-project__corner.rb {
    transform-origin: right bottom;
    right: -1.5rem;
    bottom: -1.5rem;
  }

  .pick-project__corner.rt {
    transform-origin: right top;
  }

  .pick-project__container--reverse .pick-project__corner.lb {
    left: -1.5rem;
  }

  /*-------------- JOIN US --------------*/

  .join-us {
    margin: 6rem 0;
  }

  .join-us__container {
    padding: 2.75rem;
  }

  .join-us__cta {
    margin-top: 2.5rem;
  }
}
